<template>
  <article class="expedientes">
    <header>
      <h3>Servicio farmacéutico Rionegro - Expedientes{{ addTitle }}</h3>
      
      <article>
        <div v-if="isAdmin">
          <div>
          
            <v-select
              v-model="bodega"
              :items="statusOptions"
              label="Seleccione Bodega"
              outlined
              @change="handleStatusChange"
            ></v-select>
          </div>
          <div>
          
            <v-select
              v-model="contrato"
              :items="statusOptions"
              label="Seleccione Contrato"
              outlined
              @change="handleStatusChange"
            ></v-select>
          </div>
          <div>
            <!-- <v-select
              v-model="estado"
              :items="statusOptions"
              label="Seleccione Estado"
              outlined
              @change="handleStatusChange"
            ></v-select> -->
          </div>
          
        </div>
        <!-- <div>
          <div>
          
            <v-text-field
              v-model="codigoInterno"
              :counter="10"            
              label="Código Interno"
              hide-details
              required
            ></v-text-field>
          </div>
          <div>
          
            <v-text-field
              v-model="afiliado"
              :counter="10"            
              label="Afiliado"
              hide-details
              required
            ></v-text-field>
          </div>
          <div class="calendar">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              >
              <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  v-model="fecha"
                  :counter="10"                
                  label="Desde"
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                  required
                  ></v-text-field>
              </template>
              <v-date-picker 
                  v-model="fecha" 
                  @input="menu = false"
              ></v-date-picker>
            </v-menu>
            <figure><v-icon class="me-2">calendar_month</v-icon></figure>
          </div>
          <div class="calendar">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              >
              <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  v-model="fechaDisponibilidad"
                  :counter="10"                
                  label="Hasta"
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                  required
                  ></v-text-field>
              </template>
              <v-date-picker 
                  v-model="fechaDisponibilidad" 
                  @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
            <figure><v-icon class="me-2">calendar_month</v-icon></figure>
          </div>
        </div> -->
      </article>
      <section>
        <v-btn v-if="expedientesSeleccionados.length>0" variant="elevated" color="primary" @click="cambiarEstado(expediente)">
          Cambiar estado
        </v-btn>
        <v-btn v-if="expedientesSeleccionados.length>0" variant="elevated" color="primary" @click="descargarExpedientes(expediente)">
          Descargar
        </v-btn>
        <!-- <v-btn variant="elevated" color="secundary" @click="crearExpediente">
          Crear Expediente
        </v-btn> -->
        <v-btn variant="elevated" color="primary" @click="cambiarVista">
          <v-icon class="me-2">change_circle</v-icon>
        </v-btn>
      </section>
    </header>
    <section v-if="vista">
      <ul>
        <li v-for="(expediente, index) in expedientes" :key="index" class="completa">
          <div class="expediente-folder">
            <v-img src="@/assets/images/folder.png"></v-img>
          </div>
          <header @click="redirectToExpediente(expediente)">
            <article>
              <div class="expediente-id">{{ expediente.idExpediente }}</div>
              <!-- <h3>Completa</h3> -->
              <h4>{{ formatFecha(expediente.createDate) }}</h4>
              <h5><span>Código interno:</span> 121{{ index }}</h5>
            </article>            
          </header>
          <div @click="redirectToExpediente(expediente)">
            <ol>
              <li>
                <figure><v-icon class="me-2">person</v-icon></figure>
                <h5>{{ expediente.afiliado.nombreCompleto }}</h5>
              </li>
              <li>
                <figure><v-icon class="me-2">badge</v-icon></figure>
                <h5>{{ expediente.afiliado.numeroDocumento }}</h5>
              </li>              
              <li>
                <figure><v-icon class="me-2">bookmark</v-icon></figure>
                <h5>{{ expediente.estado }}</h5>
              </li>              
            </ol>
          </div>
          <footer>
            <div>
              <!-- <figure @click="redirectToExpediente(expediente)"><v-icon class="me-2">visibility</v-icon></figure> -->
              <figure @click="cambiarEstado(expediente)"><v-icon class="me-2">change_circle</v-icon></figure>
              <figure>
                <v-icon class="me-2" @click="descargarExpediente(expediente)" title="Descargar">download</v-icon>
              </figure>
              <figure>
                <v-checkbox
                  v-model="expedientesSeleccionados"
                  :value="expediente"              
                  color="primary"
                  hide-details
                />
              </figure>
            </div>            
          </footer>
        </li>
      </ul>
    </section>
    <section v-if="!vista">
      <v-container>
        <v-row>
          <!-- Encabezados de la tabla -->
          <v-col cols="12">
            <div class="list-header">
              <div class="list-cell icon">Carpeta</div>
              <div class="list-cell small">ID</div>
              <div class="list-cell">Fecha</div>
              <div class="list-cell">Código Interno</div>
              <div class="list-cell large">Afiliado</div>
              <div class="list-cell">Documento</div>
              <div class="list-cell">Estado</div>
              <div class="list-cell">Acciones</div>
            </div>
          </v-col>

          <!-- Filas de la lista -->
          <v-col cols="12" v-for="(expediente, index) in expedientes" :key="index">
            <div class="list-row">
              <div class="list-cell icon" @click="redirectToExpediente(expediente)">
                <v-img src="@/assets/images/folder.png" max-width="40" />
              </div>
              <div class="list-cell small" @click="redirectToExpediente(expediente)">{{ expediente.idExpediente }}</div>
              <div class="list-cell" @click="redirectToExpediente(expediente)">{{ formatFecha(expediente.createDate) }}</div>
              <div class="list-cell" >{{ index }}</div>
              <div class="list-cell large" @click="redirectToExpediente(expediente)">{{ expediente.afiliado.nombreCompleto }}</div>
              <div class="list-cell" @click="redirectToExpediente(expediente)">{{ expediente.afiliado.numeroDocumento }}</div>
              <div class="list-cell" @click="redirectToExpediente(expediente)">{{ expediente.estado }}</div>
              <div class="list-cell actions">
                <v-icon @click="dialog = true" title="Cambiar estado">change_circle</v-icon>
                <v-icon @click="descargarExpediente(expediente)" title="Descargar">download</v-icon>
                <v-checkbox 
                  v-model="expedientesSeleccionados"
                  :value="expediente"
                  color="primary"
                  hide-details
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Cambiar Estado del Expediente</v-card-title>
        
        <v-card-text>          
          <v-select
            v-model="selectedStatus"
            :items="statusOptions"
            label="Seleccione Estado"
            outlined
            @change="handleStatusChange"
          ></v-select>
          
          <!-- <v-textarea
            v-if="selectedStatus === 'Revisar por Servicio Farmaceutico'"
            v-model="observation"
            label="Observación"
            outlined
            rows="3"
          ></v-textarea> -->
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="saveStatus">Guardar</v-btn>
          <v-btn color="red darken-1" text @click="dialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </article>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import moment from 'moment';
import Swal from 'sweetalert2';

export default {
  name: 'Index',
  data: () => ({
    tab: null,
    rolesKeycloak: null,
    codigosEmpresas: [],
    dataEmpresas: [],
    expedientes: [],
    usuario: "",
    estado: "",
    bodega: "",
    afiliado: "",
    codigoInterno: "",
    contrato: "",
    fecha: "",
    fechaDisponibilidad: "",
    bodegaId: "",
    vista: true,
    expediente: {},
    isAdmin: false,

    dialog: false, 
    selectedStatus: null, 
    observation: '', 
    statusOptions: [
      'AUDITADO',
      'COMPLETO',
      'RADICADO',
      'DEVUELTO',
    ],
    addTitle:"",

    menu: false,
    menu2: false,

    expedientesSeleccionados: []
  }),
  mounted() {
     
    this.usuario = this.auth.username;
    const savedVista = localStorage.getItem("vista");
    this.vista = savedVista !== null ? JSON.parse(savedVista) : true;
    // this.empresasUsuario();

    this.getBodega();

    const vuexState = JSON.parse(localStorage.getItem('vuex'));
    
    if(vuexState.auth.roles.includes("Expediente_Administrador")){
      this.isAdmin=true;
    }
    if(vuexState.auth.roles.includes("Expediente_Auditor")){
      this.addTitle=" - Auditor";
      this.statusOptions= [
        'AUDITADO',
        'DEVUELTO',
      ];
    }
    if(vuexState.auth.roles.includes("Expediente_Radicador")){
      this.addTitle=" - Radicador";
      this.statusOptions= [
        'RADICADO',
        'DEVUELTO',
      ];
    }

  },
  computed: {
    ...mapState(["auth", "enterprise"]),
  },

  methods: {
    ...mapMutations(["setEnterprise"]),
    getExpedientes() {      
            this.$http.get(`/msa-process-file/api/expediente/listarExpediente?idEmpresa=1&numeroDocumento=&codigoBodega=${this.bodegaId}`)
        .then(({ data }) => {
          console.log(data);          
          this.expedientes = data
        })
        .catch(err => console.log(err));        
    },
    formatFecha(fecha) {
      return moment(fecha).format('YYYY/MM/DD');
    },
    formatFecha2(fecha) {
      return moment(fecha).format('HH:mm');
    },
    empresasUsuario() {
      const codEmpresas = this.$http.get(`msa-setting/api/usem/relacion/${this.usuario}`)
        .then(({ data }) => this.codigosEmpresas = data)
        .catch(err => console.log(err))

      if (codEmpresas != []) {
        this.$http.post('msa-setting/api/empresa/list', this.codigosEmpresas)
          .then(({ data }) => this.dataEmpresas = data)
          .catch(err => console.log(err))
      }
    },
    toEnterprise(enterprise) {
      this.auth.roles = this.rolesKeycloak;
      this.setEnterprise(enterprise)
    },
    getBodega() {
      this.$http.get(`msa-process-file/api/file/getBodegaUsuario`)
        .then(({ data }) => {
          console.log(data);
          this.bodegaId=data;
          this.getExpedientes();
        })
        .catch(err => console.log(err))
    },
    cambiarEstado(expediente) {
      this.dialog=true;
      this.expediente= expediente;
    },
    descargarExpediente(expediente) {
      console.log(expediente);
      
      this.$http.get(`msa-process-file/api/expediente/descargarExpediente?ruta=dispensacion/${this.bodegaId}/expediente/${expediente.afiliado.numeroDocumento}/${expediente.idExpediente}/`,
        {
          responseType: 'arraybuffer'
        }
      )
        .then(({ data }) => {
          console.log(data);
          const fileBlob = new Blob([data], { type: 'application/zip' });

          const link = document.createElement('a');
          const url = window.URL.createObjectURL(fileBlob);
          
          link.href = url;
          link.setAttribute('download', 'archivo.zip'); 
          
          document.body.appendChild(link);
          link.click();
          
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(err => console.log(err))
    },
    cambiarVista() {
      this.vista=!this.vista;
      localStorage.setItem("vista", this.vista);
    },
    descargarExpedientes() {

    },
    redirectToExpediente(expediente) {

      // const queryParams = '';
      // const redirectUrl = '/#/modules/expediente/expediente/'+expediente.idExpediente;
      // window.location.href = `${redirectUrl}${queryParams}`;
      // window.location.reload();

      this.$router.push({ path: '/modules/expediente/expediente/'+expediente.idExpediente }); 
    },

    crearExpediente() {
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0];
      let data= {
        "documentoAfiliado": "11111111",
        "fechaFormulacion": formattedDate,    
        "cie": "", 
        "codMipres": "",
        "bodega": {
            "bodegasId": {
                "idEmpresa": 1,
                "codigoBodega": this.bodegaId
            }
        },
        "tipoArchivo": "ATTACHMENT",
        "documentoMedico": "",
        "nombreArchivo": "sin-formula.pdf",
        "detalleProductos": []
      }

      this.$http
      .post("msa-process-file/api/expediente/crearExpediente", data)
      .then((result) => {
        console.log("Ips: ", result);
        if(result.data && result.data.nombrePagador){
          this.ips= result.data.nombrePagador;
          this.estadoIps= true;
          this.ipsConfirmado= true;
        }else{
          this.ips= "";
          this.estadoIps= false;
        }
      });
    },

    handleStatusChange() {      
      if (this.selectedStatus !== 'Revisar por Servicio Farmaceutico') {
        this.observation = '';
      }
    },
    saveStatus() {      
      
      let data= {
        estado: this.selectedStatus
      }

      this.$http
      .put("msa-process-file/api/expediente/estadoExpediente/"+this.expediente.idExpediente, data)
      .then((result) => {
        console.log("Estado: ", result);
        this.dialog = false;
        if(result.data.idExpediente){
          Swal.fire({
            title: 'Expediente',
            text: 'El estado del expediente ha sido actualizado',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          });
          this.expedientes = this.expedientes.map(exp => {
            if (exp.idExpediente === this.expediente.idExpediente) {
              return { ...exp, estado: this.selectedStatus }; 
            }
            return exp; 
          });
          this.selectedStatus=null;
        }else{
          Swal.fire({
            title: 'Expediente',
            text: 'Hubo un error, intente nuevamente',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        }
        // if(result.data && result.data.nombrePagador){
        //   this.ips= result.data.nombrePagador;
        //   this.estadoIps= true;
        //   this.ipsConfirmado= true;
        // }else{
        //   this.ips= "";
        //   this.estadoIps= false;
        // }
      });
            
      console.log('Estado seleccionado:', this.selectedStatus);
      console.log('Observación:', this.observation);
      
      
    },

  }
}
</script>

<style scoped>

ul, ol {
  list-style: none;
}

article.expedientes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}


article.expedientes > header {
  border-bottom: .1rem solid #ccc;
  padding-top: 1rem;
  padding-bottom: 1rem;   
  width: 100%;
  position: inherit;
  background: white;
  -webkit-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.23);
  box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

article.expedientes > header h3 {
  padding-left: 1rem;
  margin-bottom: .5rem;
  padding-left: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  color: #0D47A1;
  width: 90%;
}

article.expedientes > header > article {
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;  
  position: relative;
}

article.expedientes > header > article > div {  
  width: 100%;  
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  
}

article.expedientes > header > article .calendar {  
  position: relative;  
}

article.expedientes > header > article .calendar figure{  
  position: absolute;
  right: 10px;
  top: 15px;
}

article.expedientes > header > article > div > div {
  margin: 0 1rem;
  width: 100%;  
}

article.expedientes > header > article > div:first-child > div:first-child {
  margin-top: -1px;
}

article.expedientes > header > article div label {
  margin-left: .5rem;
  width: 100%;
}

article.expedientes > header > section {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0 0 0;
  width: 90%;
}

article.expedientes > header > section button{
  margin-left: 10px;
}

article.expedientes i {
  margin-right: 0 !important;
}

article.expedientes > section {
  width: 100%;
}

article.expedientes > section ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  align-items: stretch;
  padding: 0
}

article.expedientes > section ul > li {
  /* aspect-ratio: 1 / 1; */
  /* background: white; */
  border-radius: 1rem;
  cursor: pointer;
  padding: 1%;
  margin: 2% 1%;
  width: 20%;
  min-width: 210px;
  position: relative;
  /* width: 260px;   */
  width: 180px;  
}

article.expedientes > section ul > li .expediente-folder{
  position: absolute;
  width: 100%;  
  z-index: 1;
  top: -55px;
  left: 0px;
}

article.expedientes > section ul > li .expediente-folder .v-image__image{  
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  /* background-image: url("../assets/images/folder.JPG");  */
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
}


article.expedientes > section ul > li header{
  position: relative;
  z-index: 2;
}

article.expedientes > section ul > li header article h3 {
  font-size: 1.3rem;
  margin-bottom: .5rem;
  text-align: center;
}

article.expedientes > section ul > li header article h4 {
  font-weight: 400;
  font-size: .9rem;
  text-align: right;  
  margin-top: -14px;
}

article.expedientes > section ul > li header article h5 {
  font-weight: 500;
  font-size: 11px;
  text-align: left;
  margin-top: 10px;
}

article.expedientes > section ul > li header article h5 span {
  font-weight: 800;  
}

article.expedientes > section ul > li header aside {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 1rem;
}

article.expedientes > section ul > li header .expediente-id {
  position: absolute;
  width: 30px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  border: 2px solid #0d47a1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  left: 0px;
  top: -10px;
  font-weight: bold;
  font-size: 13px;
}

article.expedientes > section ul > li header aside figure {
  background: #0d47a1;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  width: 50px;
}

article.expedientes > section ul > li header aside figure i {
  color: white;
  font-size: 2rem;
}

article.expedientes > section ul > li div {
  margin-top: .5rem;
  z-index: 2;
  position: relative;
}

article.expedientes > section ul > li div ol {
  padding: 0;
}

article.expedientes > section ul > li div ol li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: .5rem;
}

article.expedientes > section ul > li div ol li:last-child {
  margin-bottom: 0;
}

article.expedientes > section ul > li div ol li h5{
  /* white-space: nowrap; */
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
}

article.expedientes > section ul > li div ol li figure {
  margin-right: .5rem;
  width: 30px;
  height: 22px;
}
article.expedientes > section ul > li div ol li figure i{
  border-radius: 50%;
  font-size: 1rem;
  padding: .2rem;
}

article.expedientes > section ul > li div ol li figure i {
  border: 1px solid #0D47A1;
  color: #0D47A1;
}

article.expedientes > section ul > li div ol li.incompleta figure i {
  border: 1px solid orange;
  color: orange;
}

article.expedientes > section ul > li div ol li.error1 figure i {
  border: 1px solid red;
  color: red;
}

article.expedientes > section ul > li > footer > div {
  display: flex;
  justify-content: space-between;  
  cursor: pointer;
  height: 45px;
  align-items: center;
  margin-top: 10px;
  border-bottom: 1px solid #0D47A1;
}

article.expedientes > section ul > li > footer > div  figure{
  width: 40px;
  display: flex;
  justify-content: center;
}

article.expedientes > section ul > li > footer > div  figure button{
  margin: 0;
}

article.expedientes > section ul > li > footer > div  figure i{  
}

article.expedientes > section ul > li > footer > div  figure > *{
  margin: 0;
  padding: 0;
}

article.expedientes > section ul > li > footer > section {
  width: 100%;
  display: flex;
  border-top: 1px solid gray;
  padding-top: 10px;
  justify-content: center;
}

article.expedientes > section ul > li > footer > section p{
  font-size: 1rem;
  margin: 0;
  margin-left: 3px;
}

article.expedientes > section ul > li.completa header article h3 {
  color: #0d47a1;
}

article.expedientes > section ul > li.incompleta header article h3 {
  color: orange;
}

article.expedientes > section ul > li.incompleta header aside figure {
  background: orange;
}

article.expedientes > section ul > li.revision header article h3 {
  color: green;
}

article.expedientes > section ul > li.revision header aside figure {
  background: green;
}

article.expedientes > section ul > li.rechazada header article h3 {
  color: red;
}

article.expedientes > section ul > li.rechazada header aside figure {
  background: red;
}
.list-cell {
  flex: 1;
  text-align: left;
  padding: 5px;
  display: flex;
  justify-content: center;
}
.list-cell.actions {
  display: flex;
}
.list-cell.actions button{
  margin-right: 10px;
}
.list-cell.actions .v-input--checkbox {
  margin-top: 0;
}

.list-cell.icon {
  flex: 0.3; /* Espacio reservado para el icono */
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-cell.small {
  flex: 0.5; /* Más pequeño */
  font-size: 0.9rem;
}

.list-cell.large {
  flex: 2; /* Más ancho para nombres largos */
}

.list-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: bold;
  background-color: #f5f5f5;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.list-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  align-items: center;
}

@media (min-width: 960px) {
    .container {
        max-width: initial;
    }
}
</style>
<style>
article.expedientes .v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
</style>